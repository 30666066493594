<template>
<div class="chakan">
  <div class="body">
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="最新成交" name="1"></el-tab-pane>
    </el-tabs>
    <el-table
            :data="pricelist"
            stripe
            style="width: 100%">
      <el-table-column
              prop="strDate"
              class="goods-message"
              align="center"
              label="日期"
      >
      </el-table-column>
      <el-table-column
              prop="times"
              class="goods-message"
              align="center"
              label="项目编号"
      >
      </el-table-column>
      <el-table-column
              prop="productname"
              class="goods-message"
              align="center"
              label="标的名称"
      >
      </el-table-column>
      <el-table-column
              prop="productname"
              class="goods-message"
              align="center"
              label="标的类型"
      >
      </el-table-column>
      <el-table-column
              prop="delivery"
              class="goods-message"
              align="center"
              label="标的所在地"
      >
      </el-table-column>
      <el-table-column
              prop="price"
              class="goods-message"
              align="center"
              label="挂牌价"
      >
      </el-table-column>
      <el-table-column
              prop="outbidprice"
              class="goods-message"
              align="center"
              label="最高成交价"
      >
      </el-table-column>
    </el-table>
    <!--    分页-->
    <div class="block" align="right">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "Chakan",
  data() {
    return {
      pricelist: [],//最新成交列表
      activeName: '1',
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
    }
  },
  mounted() {
    this.gethomePriceList()
  },
  methods:{
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      //调用查询信息接口
      // this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      //调用查询信息接口
      this.getDataList()
    },
    //最新成交列表
    gethomePriceList() {
      this.$fetch('tpproductinfo/dealList', {
        'page': this.pageIndex,
        'limit': this.pageSize,
      }, true).then((data) => {
        if (data && data.code === 0) {
          this.pricelist = data.page.list;
          // this.priceList=setTimeout(this.gethomePriceList,5000);
        } else {
          this.pricelist = []
          this.$message({
            message: data.msg,
            type: 'error',
            showClose: 'true'
          })
        }
      })
    },
  }
}
</script>

<style scoped>
@import "../assets/css/index.css";
@import "../assets/css/page/chakan.scss";
</style>